.foundation {
  .general-donation {
    &__title-container {
      background-color: $white-base;
      margin: 0px;
      width: 100%;

      &__title {
        font-size: $s-large;
        line-height: 35px;
        margin: 0px;
        padding: 40px;
        text-align: center !important;

        @media (min-width: $tablet-smaller-size) {
          font-size: $s-huge;
          line-height: 36px;
          padding: 50px;
        }
      }
    }

    &__description-container {
      background-color: $ozf-green;
      margin: 0px;
      width: 100%;

      &__description {
        padding: 40px;
        margin: auto;
        color: $white-base;
        font-family: $primary-font;
        text-align: center;
        font-size: $s-bigger;
        line-height: 30px !important;
        max-width: 1200px;

        @media (min-width: $tablet-smaller-size) {
          padding: 50px;
          font-size: $s-large;
          line-height: 35px !important;
        }
      }
    }

    &__body {
      background-color: $white-base;
      padding: 40px;
      max-width: 1000px;

      @media (min-width: $tablet-smaller-size) {
        width: calc(100% - 40px);
        margin: 70px auto;
        background-color: $white-base;
        box-shadow: 2px 4px 47px 0px rgba(0, 0, 0, 0.05);
        padding: 70px;
      }
      &__form {
        hr {
          margin: 0px 0px 25px 0px;
        }
        &__title {
          display: flex;
          font-family: $primary-font;
          font-size: $s-large;
          font-weight: $bold;
          margin: 0px 0px 20px 0px;
        }
        &__amount {
          @media (min-width: $tablet-smaller-size) {
          }

          @media (min-width: $tablet-size) {
            flex-direction: row;
            display: flex;
          }

          &__custom {
            margin-top: -14px;

            @media (min-width: $tablet-size) {
              display: block;
              margin-left: 15px;
              width: 210px;
              white-space: nowrap;
            }

            span,
            .input-wrapper {
              display: inline-block;
            }

            .error-message {
              margin-left: 14px;
            }

            span {
              margin-right: 7px;
            }
          }

          &__input {
            font-family: $primary-font;
            font-weight: $regular;
            width: 100%;
            align-items: center;

            @media (min-width: $tablet-smaller-size) {
              display: flex;
            }

            label {
              margin: 0px 15px 15px 0px;

              @media (min-width: $tablet-smaller-size) {
                margin: 0px 15px 0px 0px;
              }
            }

            fieldset {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              width: fit-content;
              margin: 7px 0px;

              @media (min-width: $tablet-smaller-size) {
                margin: 0px 5px;
              }

              span {
                label {
                  margin: 0;
                  display: block;
                }
              }
              .input-wrapper {
                margin: 0px 7px 0px 0px;
              }
            }
          }
        }
      }
    }
    &__button-container {
      display: inline-block;
      width: 100%;

      @media (min-width: $tablet-smaller-size) {
        display: flex;
        justify-content: flex-end;
      }

      button,
      .foundation-button-primary {
        width: 100%;

        @media (min-width: $tablet-smaller-size) {
          width: 200px;
          margin: 0px 0px 0px 15px;
        }
      }

      button + button {
        margin-top: 10px;

        @media (min-width: $tablet-smaller-size) {
          margin: 0px;
        }
      }
    }
  }
}
