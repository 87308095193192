.menu-red {
  color: #bf311a;
}
.menu-red:hover {
  color: #e2442a;
}
.menu-blue {
  color: #0080c5;
}
.menu-blue:hover {
  color: #00a1f8;
}
.menu-green {
  color: #5d9632;
}
.menu-green:hover {
  color: #75bc3f;
}
.menu-yellow {
  color: #e6a514;
}
.menu-yellow:hover {
  color: #eeb83f;
}
