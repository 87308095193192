body {
  background-color: #f8f8f8;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

hr {
  border: none;
  border-bottom: 1px solid $white-med;
}

hr[data-content] {
  line-height: 1rem;
  position: relative;
  text-align: center;
  height: 1.5rem;
  outline: 0;
  border: 0;

  &:before {
    content: '';
    background: $grey-base;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 0.5px;
  }

  &:after {
    display: inline-block;
    content: attr(data-content);

    color: $grey-base;

    position: relative;

    padding: 0 0.5rem;
    line-height: 1.5rem;

    background-color: $background-grey;
  }
}

/*-----------------------
Content
-----------------------*/
.content {
  display: inline-block;
  margin-top: 70px;
  position: relative;
  width: 100%;
}

// Print Confirmation Styles
@media print {
  .no-print {
    display: none !important;
  }
  .purchase-details {
    float: right !important;
    width: 320px !important;
    display: inline-block !important;
    margin-top: 0 !important;
  }
}

body.modal-open {
  overflow: hidden;
}

.conditional-form {
  @include rem(margin-bottom, 18px);

  .conditional-form-item {
    @include rem(margin-top, 18px);

    button {
      @include rem(margin-top, 18px);
    }
  }
}

.remove-item-wrapper {
  h3 {
    @include font-type($primary-sans-serif, normal, $semibold);
    @include s-base;
    @include rem(margin-bottom, 5px);
  }

  .discount {
    color: $green-base;
    @include font-type($primary-sans-serif, normal, $regular);
  }

  .remove-item {
    display: flex;

    p {
      margin: 0px;

      &:nth-child(2) {
        @include s-small;
      }
    }

    button.button-primary {
      margin-left: auto;
      background-color: transparent;
      color: $black-base;
      padding: 0px;
      text-shadow: none;
      box-shadow: none;
      min-width: 0px;
      text-decoration: underline;
      text-transform: none;
      letter-spacing: 0px;

      &:hover {
        background-color: transparent;
        color: $red-base;
      }
    }
  }
}
