/**
 * @license
 * MyFonts Webfont Build ID 4864543, 2022-05-18T14:36:37-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-45-light/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-LightItalic by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-46-light-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-Roman by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-55-roman-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-Italic by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-56-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-65-bold-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-BoldItalic by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-66-bold-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-Black by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-75-black-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-BlackItalic by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-76-black-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-UltraBlack by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-95-ultra-black-2/
 * Copyright: Copyright &amp;#x00A9; 2014, 2015 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-LightCnIta by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-48-light-condensed-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-Condensed by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-57-condensed-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-CondensedIta by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-58-condensed-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-BoldCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-67-bold-condensed-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-BoldCnIta by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-68-bold-condensed-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-BlackCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-77-black-condensed-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-BlackCnIta by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-78-black-condensed-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-ExtraBlackCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-87-extra-black-condensed-2/
 * Copyright: Copyright &amp;#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: FrutigerLTPro-ExtraBlkCnIta by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-88-extra-black-condensed-italic-2/
 * Copyright: Copyright &amp;#x00A9; 2014, 2015 Monotype Imaging Inc. All rights reserved.
 *
 *
 *
 * © 2022 MyFonts Inc
*/

@font-face {
  font-family: 'FrutigerLTPro-Bold';
  src:
    url('../../../fonts/webFonts/FrutigerLTProBold/font.woff2') format('woff2'),
    url('../../../fonts/webFonts/FrutigerLTProBold/font.woff') format('woff');
}

@font-face {
  font-family: 'FrutigerLTPro-Roman';
  src:
    url('../../../fonts/webFonts/FrutigerLTProRoman/font.woff2') format('woff2'),
    url('../../../fonts/webFonts/FrutigerLTProRoman/font.woff') format('woff');
}

// from https://fonts.googleapis.com/css?family=Amatic+SC:700

/* latin */
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/amaticsc/v26/TUZ3zwprpvBS1izr_vOMscGKfrUC_2fi-Q.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// from https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4AMP6lbBP.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfChc4AMP6lbBP.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
