/*-----------------------
Fieldset
-----------------------*/
fieldset {
  display: inline-block;
  @include rem(margin-bottom, 18px);
  position: relative;
  width: 100%;

  span span {
    float: right;

    [class*='link-'] {
      @include s-small;
      margin-top: 0px;
      vertical-align: middle;
    }
  }
}

/*-----------------------
Labels
-----------------------*/
label,
legend {
  color: $black-base;
  display: inline-block;
  @include font-type($primary-sans-serif, normal, $semibold);
  @include s-base;
  @include rem(margin-bottom, 6px);
  text-align: left;

  i {
    font-style: italic;
    font-weight: $regular;
  }

  &.label-header {
    display: block;
    font-size: $s-huge;
    font-family: $secondary-sans-serif;
    border-bottom: 1px solid $grey-light;
    @include rem(margin-bottom, 20px);
  }
}

/*-----------------------
Inputs
-----------------------*/
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
input:not([type]),
select,
textarea {
  background-color: $white-base;
  border: 1px solid darken($white-med, 10%);
  border-radius: 2px;
  box-sizing: border-box;
  appearance: none;
  transition: all 0.3s ease-in-out;
  @include font-type($primary-sans-serif, normal, 400);
  @include s-base;
  @include rem(padding, 13px);
  width: 100%;
  -webkit-font-smoothing: antialiased;

  &::placeholder {
    color: $black-base;
    font-style: italic;
  }

  &:focus {
    outline: 2px solid $black-base;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $white-light;
  }
}

.input-container {
  @include rem(padding, 18px);
  padding-bottom: 0;
  border: 1px solid $grey-light;
}

input[type='text']:focus {
  outline: 1px solid $black-base;
}

/*-----------------------
Textarea
-----------------------*/
textarea,
textarea[type='text'] {
  height: 100px;
  resize: vertical;
}

/*-----------------------
Select
-----------------------*/
select {
  background-image: url('../../images/dropdown.svg');
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  cursor: pointer;
  @include rem(padding, 8px 32px 8px 8px);
  text-overflow: ellipsis;
  width: 100%;

  option:disabled {
    color: $grey-light;
    font-style: italic;
  }
}

/*-----------------------
Checkbox
-----------------------*/
.checkbox {
  @include rem(margin-top, 15px);
  width: 100%;

  label {
    cursor: pointer;
    float: right;
    @include rem(margin-top, 2.4px);
    width: calc(100% - 32px);
  }
}

input[type='checkbox'] {
  appearance: none;
  transition: all 0.3s ease-in-out;
  background-color: $white-base;
  border: 1px solid darken($white-med, 10%);
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  @include rem(height, 20px);
  @include rem(width, 20px);
  outline: none;
  display: inline-block;
  @include rem(margin, 0px 8px 0px 0px);

  &:focus {
    outline: 2px solid $black-base;
  }

  &:checked {
    &:after {
      background-image: url('../../images/checkmark.svg');
      background-size: 17px;
      color: $blue-base;
      content: '';
      display: block;
      font-size: 17px;
      font-weight: $bold;
      height: 17px;
      transition: all 0.3s ease-in-out;
      margin-left: 1px;
      position: absolute;
      top: 2px;
      right: null;
      bottom: null;
      left: 1px;
      text-align: center;
      width: 17px;
      -webkit-position: absolute;
      -webkit-z-index: 3;
    }
  }
}

/*-----------------------
FoundationInput
-----------------------*/
.foundation-input-field {
  span:first-of-type {
    position: absolute;
    top: 8px;
    left: 15px;

    label {
      color: $grey-foundation;
      font-size: $s-small;
      margin-bottom: 4px;
    }
  }

  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  input:not([type]),
  textarea,
  select {
    height: 50px;
    border-radius: 5px;

    &::placeholder {
      font-style: normal;
    }
  }

  select {
    padding-left: 15px;
  }

  &.float-label {
    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'],
    input:not([type]),
    textarea {
      padding-top: 30px;
    }

    select {
      padding-top: 25px;
    }
  }
}

.error-message {
  color: $red-base;
  display: inline-block;
  @include rem(margin-top, 4px);
  @include s-small;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:first-letter {
    text-transform: capitalize;
  }
}

/*-----------------------
Forms
-----------------------*/

select {
  height: 49px;
}

.security-code-copy {
  font-size: $s-base;
  line-height: 19px !important;
  @include rem(margin-top, 20px);
}

.select-existing,
.add-new,
.edit-existing,
.cancel-edit {
  display: inline-block;
  position: relative;
  top: -15px;
  font-size: $s-base;
  color: $green-base;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-weight: $bold;
  background: transparent;
  padding: 0;
}

.edit-existing {
  float: right;
  color: $blue-base;

  &[aria-disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.cancel-edit {
  color: $red-base;
}

.gpi-wrapper {
  position: relative;
  &--disabled {
    pointer-events: none;

    &:after {
      border: 2px solid black;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: '';
      display: block;
      height: 20px;
      animation: spin 0.5s linear infinite;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 20px;
      margin: auto;
    }
  }
  iframe {
    width: 100%;
  }
}
