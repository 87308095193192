// Buttons Component Styles
// ========================================

// Main Button
@mixin button {
  border-radius: 20em;
  box-shadow: 0 0 18px 0 rgba(180, 180, 180, 0.5);
  display: inline-block;
  @include font-type($primary-sans-serif, normal, $bold);
  @include rem(padding, 10px);
  @include s-base;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.5px;
  line-height: normal;
  min-width: 170px;
  position: relative;
  text-align: center;
  text-decoration: none;

  &[class*='-outline'] {
    box-shadow: none;
  }

  text {
    align-items: center;
    text-decoration: none;
  }
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: none;
    outline: none;
    text-decoration: none;
  }
}

// Button Styles
@each $button in $buttons {
  $type: nth($button, 1);
  $bg-1: nth($button, 2);
  $border: nth($button, 3);
  $color: nth($button, 4);
  $hover-bg: nth($button, 5);
  $hover-color: nth($button, 6);

  .button-#{$type} {
    background-color: $bg-1;
    border: 1px solid $border;
    color: $color;
    @include button;

    &:hover:not(.is-disabled):not(.in-progress) {
      background-color: $hover-bg;
      border: 1px solid $hover-bg;
      color: $hover-color;
      opacity: 1;
    }

    &:active {
      background-color: $hover-bg;
      border: 1px solid $hover-bg;
      color: darken($hover-color, 5%);
    }

    &:focus {
      border: 1px solid $hover-bg;
    }

    &.is-disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &.in-progress {
      pointer-events: none;
      color: transparent;
      position: relative;
      text-shadow: none;

      span {
        color: transparent;
      }

      &:after {
        border: 2px solid rgba(255, 255, 255, 1);
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 20px;
        animation: spin 0.5s linear infinite;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 20px;
        margin: auto;
      }

      &[class*='-primary-outline'] {
        &:after {
          border: 2px solid $blue-base;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }

      &[class*='-alert-outline'] {
        &:after {
          border: 2px solid $yellow-base;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }

      &[class*='-secondary-outline'] {
        &:after {
          border: 2px solid $grey-base;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }

      &[class*='-green-outline'] {
        &:after {
          border: 2px solid $green-base;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }
  }

  // Links
  .link-#{$type},
  .button-link-#{$type} {
    color: $bg-1;
    display: inline-block;
    @include s-base;
    @include font-type($primary-sans-serif, normal, $bold);
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    appearance: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;
    @include rem(margin-top, 5px);

    &:hover,
    &:focus {
      color: $black-dark;
    }
  }
}

// Buton Sizes
.button-small {
  @include rem(padding, 5px 6px);
  @include s-small;
  min-width: inherit;
  width: 100px;
}

.button-large {
  @include rem(padding, 15px 12px);
}

.button-primary {
  background-color: $green-base;
  border: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  @include rem(padding, 20px);
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);

  &:hover:not(.is-disabled):not(.in-progress) {
    background-color: $green-darker;
    border: 0;
  }

  &:focus:not(.in-progress) {
    background-color: black;
    color: white;
  }
}

.back-button {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  background-image: url('../../images/back-arrow.svg');
  background-position: left;
  @include rem(padding-left, 18px);
  background-repeat: no-repeat;
  background-size: 10px;
  @include rem(margin-bottom, 20px);
  display: block;
  width: 75px;

  &:focus {
    outline: 2px solid #0077b8;
  }
}

.button-next-step {
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  &:focus {
    &:not(.in-progress) {
      color: white;
    }
  }
}

.button-selected {
  background-color: $black-base;
}

.button-primary-outline:focus {
  outline: 2px solid $blue-base;
}

.button-secondary-outline:focus {
  outline: 2px solid $blue-base;
}

.button-secondary-outline right {
  float: right;
}

.full-width {
  width: 100%;
}

.button-primary.button-green-outline {
  background-color: transparent;
  text-shadow: none;
  border: 1px solid $green-base;
  //padding: 15px;

  &:hover:not(.is-disabled):not(.in-progress),
  &:focus:not(.in-progress) {
    background-color: $green-base;
    border: 1px solid $green-base;
  }
}
