.idme-trigger {
  text-align: center;
  margin-bottom: 0;
  display: grid;

  a.idme-trigger-link {
    width: 200px;
    margin: 0 auto;
  }

  .idme-description {
    text-align: center;
    display: block;
    margin: 0 auto;

    a {
      font-size: inherit;
    }
  }
}
