.foundation {
  .background {
    width: 100%;
    background-color: $background-grey;
    font-family: $primary-font;

    .selection-group {
      width: 100%;
      max-width: 1300px;

      @media (min-width: $mobile-larger-size) {
        margin: 80px auto;
        width: calc(100% - 40px);
      }

      @media (min-width: $tablet-smaller-size) {
        display: flex;
        margin: 80px auto;
        justify-content: center;
      }

      &__tile {
        @media (min-width: $tablet-smaller-size) {
          max-width: 490px;
          width: 50%;
        }

        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;

          @media (min-width: $mobile-larger-size) {
            border-radius: 10px;
            height: auto;
          }
        }
      }

      .select-package {
        width: 100%;
        padding: 40px;
        background-color: $white-base;

        @media (min-width: $mobile-larger-size) {
          width: 100%;
          background-color: $white-base;
          border-radius: 10px;
          box-shadow: 2px 4px 47px 0px rgba(0, 0, 0, 0.05);
          padding: 50px;
          margin: 10px 0px 0px 0px;
        }

        @media (min-width: $tablet-smaller-size) {
          margin: 0px 0px 0px 10px;
        }

        @media (min-width: $desktop-size) {
          width: 55%;
        }

        &__name {
          font-family: $primary-font;
          font-weight: $bold;
          font-size: $s-larger;
          text-align: left;
          margin: 0px 0px 10px 0px;
        }

        &__product-sub-description {
          &--status,
          &--location {
            font-size: $s-medium;
            color: $grey-foundation;

            span {
              text-transform: uppercase;
            }
          }

          &--status {
            margin: 10px 0px 0px 0px;
          }

          &--location {
            margin: 0px 0px 10px 0px;
          }
        }

        &__description {
          margin-bottom: 38px;

          p {
            font-family: $primary-font;
            font-size: $s-medium;
            line-height: 24px !important;
            color: $black-header;
          }
        }

        &__secondary {
          font-size: $s-large;
          border-bottom: 1px solid $grey-light;
          padding-bottom: 15px;

          @media (min-width: $mobile-larger-size) {
            font-size: $s-larger;
            font-weight: $regular;
            border-bottom: 1px solid $grey-light;
            padding-bottom: 20px;
            margin-bottom: 45px;
          }
        }

        &__choices {
          border-bottom: 1px solid $grey-light;
          padding-bottom: 30px;
          margin-bottom: 30px;
          width: 100%;

          @media (min-width: $mobile-larger-size) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 45px;
            padding-bottom: 45px;
            align-items: flex-start;
          }

          &--line {
            width: 100%;
            display: block;
          }
          &__quantity {
            display: flex;
            flex-direction: row;
            align-items: center;

            button {
              background-color: $ozf-blue;
              color: $white-base;
              border-radius: 5px;
              height: 28px;
              width: 28px;
              font-size: $s-large;
              align-items: center;
              border: none;
              display: flex;
              justify-content: center;
              transition: all 0.3s ease-in-out;

              &:hover {
                background-color: $ozf-blue-darker;
              }
            }
            p {
              font-size: $s-big;
              margin: 0px;
            }
          }

          &__package-name {
            width: 100%;
            margin: 0px 0px 0px 0px;

            @media (min-width: $mobile-larger-size) {
              display: inline-block;
              margin-right: 0px 50px 0px 0px;
              width: 33.33%;
            }

            &__title {
              font-size: $s-bigger;
              font-weight: bold;
              margin: 0px 0px 5px 0px;

              @media (min-width: $mobile-larger-size) {
                font-size: $s-bigger;
                font-weight: bold;
              }
            }

            &__price {
              color: $grey-foundation;
              font-size: $s-medium;
              margin-bottom: 0px;
            }

            &__available {
              color: $grey-foundation;
              font-size: $s-base;
              font-style: italic;
              margin-bottom: 0px;
            }
          }

          &__perk-list {
            width: 100%;
            margin: 0px 0px 15px 10px;

            @media (min-width: $mobile-larger-size) {
              width: 33.33%;
              margin: 0px;
            }

            &__list {
              font-size: $s-medium;
              list-style-type: circle;
              display: inline-block;

              li {
                margin: 0px;
                list-style-type: disc;
                font-size: $s-medium;
                color: $grey-foundation;
              }
            }
          }
        }

        &__button-container {
          display: inline-block;
          width: 100%;

          button {
            width: 100%;

            @media (min-width: $tablet-smaller-size) {
              float: right;
              width: 200px;
            }
          }
        }
      }
    }
  }
}
