// Base Typography
// ========================================

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background-color: $white-base;
  @include font-type($secondary-sans-serif, normal, normal);
  font: 100%/1.618;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {
  font-family: $primary-sans-serif;
  @include rem(margin-bottom, 20px);
  color: $black-base;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  a {
    transition: all 0.3s ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }
}

a {
  color: $black-base;
  cursor: pointer;
  @include s-base;
  @include font-type($primary-sans-serif, normal, $bold);
  transition: all 0.3s ease-in-out;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $black-dark;
  }

  &:active,
  &:focus {
    outline: none;
  }
}

h1 {
  font-family: $secondary-sans-serif;
  text-align: center;
  font-size: $s-huge;
  margin: 1.5rem;
}

li,
p {
  line-height: 27px !important;
  font-size: 18px;
  font-weight: 100;

  strong {
    font-weight: $bold;
  }
}

b,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.error-message {
  font-family: $primary-sans-serif;
}
