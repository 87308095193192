.foundation {
  &--mobile-nav-open {
    height: 100vh;
    overflow-x: hidden;
  }

  .nav-bar {
    height: 92px;
    background-color: $black-dark;
    padding: 10px 40px;
    align-items: center;
    justify-content: space-between;
    color: $white-base;
    gap: 25px;
    display: flex;

    &--mobile-open {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
    }

    &__logo {
      height: 50px;
      width: fit-content;
      @media (min-width: $tablet-smaller-size) {
        height: 80px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: left;
      width: fit-content;

      font-family: $primary-font;
      font-size: 20px;
      line-height: 26px;
      font-weight: 300;

      text-decoration: none;
      color: $white-base;
      white-space: nowrap;
    }

    &__link {
      font-family: $primary-font;
      padding: 10px 0;
      font-size: 20px;
      line-height: 22px;
      color: $white-base;
      text-decoration: none;
      font-weight: normal;

      &:hover {
        cursor: pointer;
      }

      &--donate-btn {
        background-color: $ozf-blue;
        border-radius: 22px;
        padding: 10px 20px;
        text-align: center;
        width: 200px;
      }

      &--small {
        text-decoration: underline;
        font-size: 14px;
        position: relative;

        @media (min-width: $tablet-smaller-size) {
          font-size: 16px;
        }

        &:before {
          content: '<';
          position: absolute;
          left: -16px;
        }
      }
      &--mobile-hamburger {
        font-size: 40px;
        font-weight: 300;
      }
    }
  }

  @keyframes slidein {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
  .nav-bar-mobile {
    width: 100%;
    height: calc(100vh - 100px); // make space for the top nav
    position: fixed;
    top: 100px; // make space for the top nav
    left: 0;
    background-color: $black-dark;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: $white-base;
    gap: 0px;
    animation-duration: 1s;
    animation-name: slidein;
    z-index: 10;

    &__link {
      padding: 25px 0;
      font-size: 22px;
      line-height: 22px;
      color: $white-base;
      text-decoration: none;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid grey;

      &:hover {
        cursor: pointer;
      }

      &--donate-btn {
        background-color: $ozf-blue;
        padding: 15px 0;
        border-bottom: none;
        font-weight: 400;
      }
    }
  }
}
