//DEFINED VARIABLES
$mobile-size: em(450);
$tablet-size: em(920);
$desktop-size: em(1200);
$mobile-larger-size: 560px;
$tablet-smaller-size: em(720);

// SPECIAL VARIABLES
$tablet-landscape: em(1024);

//BORDERS
$grey-border: 1px solid $white-med;

//OFFSET
$page-header-offset: 112px;
$page-header-offset-mobile: 90px;

@mixin vertical-center {
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
}

@mixin vertical-horizontal-center {
  @include vertical-center;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

img {
  width: 100%;
}
