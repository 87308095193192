@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-secondary-50: #82cbff;
    --color-secondary-100: #6dc2ff;
    --color-secondary-200: #44b1ff;
    --color-secondary-300: #1ca0ff;
    --color-secondary-400: #008df2;
    --color-secondary-500: #0075c9;
    --color-secondary-600: #005491;
    --color-secondary-700: #003459;
    --color-secondary-800: #001321;
    --color-secondary-900: #000d17;

    --color-danger-50: #eba8aa;
    --color-danger-100: #e7979a;
    --color-danger-200: #df767a;
    --color-danger-300: #d8555a;
    --color-danger-400: #d03439;
    --color-danger-500: #b2292e;
    --color-danger-600: #841e22;
    --color-danger-700: #571416;
    --color-danger-800: #29090b;
    --color-danger-900: #290407;

    --color-danger: var(--color-danger-500);
    --color-secondary: var(--color-secondary-500);

    --color-thematic-yellow-50: #ffd788;
    --color-thematic-yellow-100: #ffd073;
    --color-thematic-yellow-200: #ffc34a;
    --color-thematic-yellow-300: #ffb522;
    --color-thematic-yellow-400: #f8a500;
    --color-thematic-yellow-500: #cf8a00;
    --color-thematic-yellow-600: #976500;
    --color-thematic-yellow-700: #5f3f00;
    --color-thematic-yellow-800: #271a00;
    --color-thematic-yellow-900: #1e1400;

    --color-thematic-green-50: #bbdfa0;
    --color-thematic-green-100: #b0da91;
    --color-thematic-green-200: #9bd072;
    --color-thematic-green-300: #85c654;
    --color-thematic-green-400: #70b53c;
    --color-thematic-green-500: #5d9632;
    --color-thematic-green-600: #436c24;
    --color-thematic-green-700: #294216;
    --color-thematic-green-800: #0f1808;
    --color-thematic-green-900: #141b0d;
  }

  .theme-foundation {
    font-family: FrutigerLTPro-Roman, sans-serif;
    --color-primary-50: var(--color-thematic-yellow-50);
    --color-primary-100: var(--color-thematic-yellow-100: );
    --color-primary-200: var(--color-thematic-yellow-200: );
    --color-primary-300: var(--color-thematic-yellow-300: );
    --color-primary-400: var(--color-thematic-yellow-400: );
    --color-primary-500: var(--color-thematic-yellow-500: );
    --color-primary-600: var(--color-thematic-yellow-600: );
    --color-primary-700: var(--color-thematic-yellow-700: );
    --color-primary-800: var(--color-thematic-yellow-800: );
    --color-primary-900: var(--color-thematic-yellow-900: );
    --color-primary: var(--color-primary-500);

    --color-tertiary-50: var(--color-thematic-green-50);
    --color-tertiary-100: var(--color-thematic-green-100: );
    --color-tertiary-200: var(--color-thematic-green-200: );
    --color-tertiary-300: var(--color-thematic-green-300: );
    --color-tertiary-400: var(--color-thematic-green-400: );
    --color-tertiary-500: var(--color-thematic-green-500: );
    --color-tertiary-600: var(--color-thematic-green-600: );
    --color-tertiary-700: var(--color-thematic-green-700: );
    --color-tertiary-800: var(--color-thematic-green-800: );
    --color-tertiary-900: var(--color-thematic-green-900: );
    --color-tertiary: var(--color-tertiary-500);
  }

  .theme-society {
    font-family: Roboto, sans-serif;
    --color-primary-50: var(--color-thematic-green-50);
    --color-primary-100: var(--color-thematic-green-100);
    --color-primary-200: var(--color-thematic-green-200);
    --color-primary-300: var(--color-thematic-green-300);
    --color-primary-400: var(--color-thematic-green-400);
    --color-primary-500: var(--color-thematic-green-500);
    --color-primary-600: var(--color-thematic-green-600);
    --color-primary-700: var(--color-thematic-green-700);
    --color-primary-800: var(--color-thematic-green-800);
    --color-primary-900: var(--color-thematic-green-900);
    --color-primary: var(--color-primary-500);

    --color-tertiary-50: var(--color-thematic-yellow-50);
    --color-tertiary-100: var(--color-thematic-yellow-100);
    --color-tertiary-200: var(--color-thematic-yellow-200);
    --color-tertiary-300: var(--color-thematic-yellow-300);
    --color-tertiary-400: var(--color-thematic-yellow-400);
    --color-tertiary-500: var(--color-thematic-yellow-500);
    --color-tertiary-600: var(--color-thematic-yellow-600);
    --color-tertiary-700: var(--color-thematic-yellow-700);
    --color-tertiary-800: var(--color-thematic-yellow-800);
    --color-tertiary-900: var(--color-thematic-yellow-900);
    --color-tertiary: var(--color-tertiary-500);
  }
}

@layer utilities {
  .sawtooth-bottom {
    @apply relative bg-white;
  }

  .sawtooth-bottom::after {
    background: linear-gradient(-45deg, transparent 20px, #fff 0),
      linear-gradient(45deg, transparent 20px, #fff 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 25px 25px;
    content: ' ';
    display: block;
    position: absolute;
    bottom: -40px;
    left: 0px;
    width: 100%;
    height: 40px;
  }

  .aspect-square {
    aspect-ratio: 1 / 1;
  }

  .w-fit {
    width: fit-content;
  }

  .rendered-html * {
    @apply m-0;
  }

  .rendered-html ul,
  .rendered-html ol {
    @apply list-disc list-inside my-0.5;
  }

  .rendered-html li {
    @apply leading-none;
  }
}

html,
body {
  @apply h-full;
}

#root {
  @apply h-full overflow-y-auto;
}

.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker-wrapper {
  @apply w-full;
}
