//MAIN COLORS

//BLACK
$black-light: #44525a; //passes WCAG AAA against white
$black-base: #5d5d5d; //passes WCAG AA against white
$black-dark: #15191c;

//BLUE
$blue-light: #e5f2f9;
$blue-base: #0077b8;
$blue-dark: #51abc0;
$blue-darker: #414a61;

//GREEN
$green-light: #edf9e4;
$green-base: #5c9632;
$green-dark: #5b945c;
$green-darker: #467326;

//GREY
$background-grey: #f7f7f7;
$grey-light: #ececec;
$grey-med: #f1f1f1;
$copy-grey: #75777b; //fails WCAG AA against white
$grey-base: #a9a9a9; //fails WCAG AA against white
$grey-dark: #767676; //passes WCAG AA against white
$grey-foundation: #575757; //passes WCAG AAA against white
$black-header: #434343; //passes WCAG AAA against white

//RED
$red-light: #f9e9e9;
$red-base: #bf321a;
$red-dark: #b30016;

//YELLOW
$yellow-light: #ffd980;
$yellow-base: #e6a514;
$yellow-dark: #e5a100;

//WHITE
$white-light: #f4f6f6;
$white-base: #ffffff;
$white-med: #ececec;
$white-dark: #949494;

$purple-base: #af8dd8;
$primary-color: $blue-base;

//ORANGE
$orange-base: #e6a514;

//BRAND COLORS
// Green
$ozf-green: #528a3e;
$ozf-green-darker: darken($ozf-green, 25%);
// Yellow
$ozf-yellow: #e99e26;
$ozf-yellow-darker: darken($ozf-yellow, 10%);
// Red
$ozf-red: #c32126;
$ozf-red-darker: darken($ozf-red, 25%);
// Blue
$ozf-blue: #0c69aa;
$ozf-blue-darker: darken($ozf-blue, 25%);
$ozf-blue-lighter: lighten($ozf-blue, 55%);

//MAIN PALETTE LIST
$colors: (
  black-light $black-light,
  black-base $black-base,
  black-dark $black-dark,
  blue-light $blue-light,
  blue-base $blue-base,
  blue-dark $blue-dark,
  blue-darker $blue-darker,
  grey-light $grey-light,
  grey-base $grey-base,
  grey-med $grey-med,
  grey-dark $grey-dark,
  green-base $green-base,
  green-dark $green-dark,
  red-light $red-light,
  red-base $red-base,
  red-dark $red-dark,
  yellow-light $yellow-light,
  yellow-base $yellow-base,
  yellow-dark $yellow-dark,
  white-light $white-light,
  white-base $white-base,
  white-dark $white-dark
);
