/*-----------------------
Flash
-----------------------*/
.flash-message {
  @include rem(padding, 10px 20px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: null;
  left: 0;

  z-index: 99999999; // allow flash message to appear on top of modals

  p {
    font-weight: bold;
    font-size: $s-bigger;
    text-align: center;
  }

  &.success {
    background-color: $green-light;

    p {
      color: $green-base;
    }
  }

  &.failure {
    background-color: $red-light;

    p {
      color: $red-base;
    }
  }
}

//ANIMATION
.flash-enter {
  opacity: 0.01;

  &.flash-enter-active {
    opacity: 1;
    transition: opacity 0.8s ease-in;
  }
}

.flash-exit {
  opacity: 1;

  &.flash-exit-active {
    opacity: 0.01;
    transition: opacity 0.8s ease-in;
  }
}
