.tag-container {
  padding: 1rem 0;
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 1rem;
  }

  .tag {
    @include rem(padding, 12px 20px);
    @include rem(margin, 0 1rem 7px 0);
    display: inline-block;
    background: $white-base;
    font-family: $primary-sans-serif;
    font-weight: $light;
    border: 1px solid $white-med;
    border-radius: 500px;
    cursor: pointer;

    &-selected {
      border: 2px solid rgba(230, 165, 20, 0.8);
    }
  }
}
