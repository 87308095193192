.delivery-options {
  label {
    font-weight: $regular;
    padding: 6px;
    padding-right: 0;
    vertical-align: -2;
    font-size: 18px;
    padding: 6px;
    padding-right: 0;
    margin-bottom: 0;
  }

  &-mail-address {
    padding: 1rem;
    margin-left: 1rem;
    p {
      margin-bottom: 0;
      font-size: 1rem;

      &.header {
        font-weight: bolder;
      }
    }
  }
}
