.recipient-info {
  width: 100%;
  max-width: 1300px;

  @media (min-width: $mobile-larger-size) {
    margin: 80px auto;
    width: calc(100% - 40px);
  }

  @media (min-width: $tablet-smaller-size) {
    display: flex;
    margin: 80px auto;
    justify-content: center;
  }

  &__tile {
    @media (min-width: $tablet-smaller-size) {
      max-width: 490px;
      width: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
      object-fit: cover;

      @media (min-width: $mobile-larger-size) {
        border-radius: 10px;
        height: auto;
      }
    }
  }

  &__body {
    width: 100%;
    padding: 40px;
    background-color: $white-base;

    @media (min-width: $mobile-larger-size) {
      width: 100%;
      background-color: $white-base;
      border-radius: 10px;
      box-shadow: 2px 4px 47px 0px rgba(0, 0, 0, 0.05);
      padding: 50px;
      margin: 10px 0px 0px 0px;
    }

    @media (min-width: $tablet-smaller-size) {
      margin: 0px 0px 0px 10px;
    }

    @media (min-width: $desktop-size) {
      width: 55%;
    }

    &__steps {
      text-align: right;
      font-size: $s-base;
      font-style: italic;
    }

    &__titles {
      width: 100%;
      margin-bottom: 0px;

      @media (min-width: $mobile-larger-size) {
        display: flex;
        justify-content: space-between;
      }

      &__animal {
        margin: 0px;
      }

      &__package {
        font-size: $s-medium;
        margin: 10px 0px 0px;

        @media (min-width: $mobile-larger-size) {
          text-align: right;
          margin: 0px;
          font-size: $s-large;
        }
      }
    }

    hr {
      margin: 25px 0px 25px 0px;
    }

    &__button-container {
      display: inline-block;
      width: 100%;

      @media (min-width: $tablet-smaller-size) {
        display: flex;
        justify-content: flex-end;
      }

      button,
      .foundation-button-primary {
        width: 100%;

        @media (min-width: $tablet-smaller-size) {
          width: 200px;
          margin: 0px 0px 0px 15px;
        }
      }

      button + button {
        margin-top: 10px;

        @media (min-width: $tablet-smaller-size) {
          margin: 0px;
        }
      }
    }

    &__radio {
      width: 100%;
      margin-bottom: 10px;
      // the way that <Field /> components get rendered, they render each child option with the same
      // class name, so we have to target the same class as a child of itself in order to style each
      // individual option
      .recipient-info__right-column__radio {
        display: inline-flex;
        flex-direction: row-reverse;
        width: auto;
      }

      span {
        display: block;
        width: 100%;
        margin-right: 30px;

        label {
          font-weight: $regular;
          margin-bottom: 15px;
        }
      }
    }

    &__package-designation {
      p {
        margin-bottom: 5px;
        color: $grey-base;
        font-size: $s-medium;
      }
    }

    h1 {
      font-size: $s-larger;
      font-weight: $bold;
    }
  }
}
